import { ActionTree, MutationTree, GetterTree } from 'vuex';
import { isHealthPath } from '../router/utils';
import { trimWpDomainInPlace } from '~/utils/trimWpDomain';
import {
  FETCH_MENUS_IF_NEEDED,
  FETCH_REGULAR_MENUS,
  FETCH_HEALTH_MENUS,
  UPDATE_ALL_MENUS,
  UPDATE_REGULAR_MENU_LOADED,
  UPDATE_HEALTH_MENU_LOADED,
} from '~/constants';

import { defaultDataCaching as dataCaching } from '~/libs/cacheStore';

export const namespaced = false;

export type Menu = {
  type: string;
  title: string;
  items: MenuItem[];
}

export interface Menus {
  loaded: boolean;
  main: Menu | null;
  second: Menu | null;
  info: Menu | null;
  rubric: Menu | null;
  projects: Menu | null;
  subscribe: Menu | null;
  apps: Menu | null;
  rules: Menu | null;
  burgerInfo: Menu | null
  burgerCategories: Menu | null
  burgerSubs: Menu | null
}

export interface MenusState {
  regular: Menus;
  health: Menus;
}

export const state = (): MenusState => ({
  regular: {
    loaded: false,
    main: null,
    second: null,
    info: null,
    rubric: null,
    projects: null,
    subscribe: null,
    apps: null,
    rules: null,
    burgerInfo: null,
    burgerCategories: null,
    burgerSubs: null,
  },
  health: {
    loaded: false,
    main: null,
    second: null,
    info: null,
    rubric: null,
    projects: null,
    subscribe: null,
    apps: null,
    rules: null,
    burgerInfo: null,
    burgerCategories: null,
    burgerSubs: null,
  },
});

const MENUS_NAMES_IN_SERVER = {
  main: ['twenty-main'],
  second: ['twenty-second', 'health-menu-header-2'],
  projects: ['twenty-main-projects', 'health-menu-header-1'],
  info: ['twenty-info-footer', 'health-menu-footer-info'],
  subscribe: ['twenty-subscribe-footer', 'health-menu-footer-subscription'],
  apps: ['twenty-apps-footer'],
  rules: ['twenty-rules-footer', 'health-menu-footer-rules'],
  burgerInfo: ['twenty-burger-info', 'health-menu-burger-info'],
  burgerCategories: ['twenty-burger-categories', 'health-menu-burger-category'],
  burgerSubs: ['twenty-burger-subscriptions', 'health-menu-burger-socials'],
};

export const actions: ActionTree<MenusState, IRootState> = {
  [FETCH_MENUS_IF_NEEDED] (context) {
    const isHealth = isHealthPath(context.rootState.route.path);
    if (isHealth && !context.state.health.loaded) {
      return context.dispatch(FETCH_HEALTH_MENUS);
    }
    if (!isHealth && !context.state.regular.loaded) {
      return context.dispatch(FETCH_REGULAR_MENUS);
    }
  },
  async [FETCH_REGULAR_MENUS] ({ commit }) {
    const cached = await dataCaching(FETCH_REGULAR_MENUS, this.$wordpressApi.fetchMenus, this.app.$redisClient);

    const data = await this.$wordpressApi.fetchMenus();
    commit(UPDATE_ALL_MENUS, { type: 'regular', data: data ?? cached });
    commit(UPDATE_REGULAR_MENU_LOADED);
  },
  async [FETCH_HEALTH_MENUS] ({ commit }) {
    const cached = await dataCaching(FETCH_HEALTH_MENUS, this.$healthApi.fetchMenus, this.app.$redisClient);

    const data = await this.$healthApi.fetchMenus();
    commit(UPDATE_ALL_MENUS, { type: 'health', data: data ?? cached });
    commit(UPDATE_HEALTH_MENU_LOADED);
  },
};

export const mutations: MutationTree<MenusState> = {
  [UPDATE_ALL_MENUS] (state, { type, data }) {
    trimWpDomainInPlace(data, ['items.link']);

    Object
      .entries(MENUS_NAMES_IN_SERVER)
      .forEach(([stateName, serverType]) => {
        const item = data.find((item: Menu) => serverType.includes(item.type));
        if (item) {
          // @ts-ignore
          state[type][stateName] = Object.freeze(item);
        }
      });
  },
  [UPDATE_REGULAR_MENU_LOADED] (state) {
    state.regular.loaded = true;
  },
  [UPDATE_HEALTH_MENU_LOADED] (state) {
    state.health.loaded = true;
  },
};

export const getters: GetterTree<MenusState, IRootState> = {
  menus: (state, _getters, rootState) => {
    return isHealthPath(rootState.route.path) ? state.health : state.regular;
  },
  burgerMenuItems: (_state, getters) => {
    const info = getters.menus.burgerInfo;
    const categories = getters.menus.burgerCategories;

    const result = [];
    if (info?.items.length) {
      result.push({ title: info?.title || 'Информация', children: info.items });
    }
    if (categories?.items.length) {
      result.push({ title: categories?.title || 'Рубрики', children: categories.items });
    }

    return result;
  },
};

import { MutationTree, ActionTree } from 'vuex';

import {
  ADD_TYPO_TEXT_AND_SHOW_FORM,
  UPDATE_TYPO_COMMENT,
  SEND_TYPO_DATA,
  HIDE_TYPO_FORM,
} from '~/constants';

export const namespaced = false;

export interface TypoState {
  isShowForm: boolean;
  text: string;
  comment: string;
  context: string;
}

export const state = (): TypoState => ({
  text: '',
  comment: '',
  context: '',
  isShowForm: false,
});

export const actions: ActionTree<TypoState, IRootState> = {
  [SEND_TYPO_DATA] ({ state, commit }) {
    const comment = state.comment || 'Нет комментария';
    const link = `<a href="${window.location.href}">${document.title || 'Ссылка'}</a>`;
    const text = state.context + '\r\n\r\n' + '<i>Комментарий: ' + comment + '</i>\r\n\r\n' + link;

    const data = {
      action: 'sendtypo',
      text: encodeURIComponent(text),
    };
    return this.$wordpressApi.sendTypoData(data)
      .then(() => {
        commit(HIDE_TYPO_FORM);
      });
  },
};

export const mutations: MutationTree<TypoState> = {
  [ADD_TYPO_TEXT_AND_SHOW_FORM] (state, { text, context }) {
    state.text = text;
    state.context = context;
    state.isShowForm = true;
  },
  [UPDATE_TYPO_COMMENT] (state, { comment }) {
    state.comment = comment;
  },

  [HIDE_TYPO_FORM] (state) {
    state.text = '';
    state.comment = '';
    state.isShowForm = false;
    state.context = '';
  },
};

import { processingHtml } from './core__html';
import { processingQuiz } from './lh__quiz';
import { processingPromoBlock } from './lh__promo-block';
import { processingScript } from './script';
import { processingQuote } from './lh__bquote';
import { processingEmailBlock } from './lh__email-block';

const processingFunctionByContentType = {
  'lh__promo-block': processingPromoBlock,
  script: processingScript,
  'lh__email-block': processingEmailBlock,
  lh__quiz: processingQuiz,
  lh__poll: processingQuiz,
  core__html: processingHtml,

  'lh__speech-bquote': processingQuote,
  'lh__author-bquote': processingQuote,
  core__pullquote: processingQuote,
  'lh__cut-bquote': processingQuote,
  'lh__simply-bquote': processingQuote,
  'lh__review-bquote': processingQuote,
} as Record<string, Function>;

export const processingContent = (content: TContent): TContent => {
  const processingFunction = processingFunctionByContentType[content.type];

  return processingFunction
    ? processingFunction(content)
    : content;
};

export function isValidEmbed ({ attributes }: TContent): boolean {
  const defaultCheck = !!attributes.oembed || !!attributes.url;

  switch (attributes.providerNameSlug) {
    case 'pinterest':
      return !!attributes.vid;
    case 'youtube':
      return defaultCheck || !!attributes.vid;
    case 'vk':
      return defaultCheck || !!attributes.vid || !!attributes.hash;
    default:
      return defaultCheck;
  }
}

import { prepareContent } from '../prepareContent';
import { QuoteAttributes } from '~/types/components/BQuote';

export function processingQuote (content: TContent<QuoteAttributes>): TContent {
  return {
    ...content,
    attributes: {
      ...content.attributes,
      details: prepareContent(content.attributes?.details || [], false),
      text: prepareContent(content.attributes?.text || [], false),
      title: prepareContent(content.attributes?.title || [], false),
    },
  };
}

import type { MutationTree, GetterTree } from 'vuex';
import type { PageType } from '~/services/health/pageType';

export const SET_PAGE_TYPE = 'SET_PAGE_TYPE';

export const namespaced = false;

export interface UIState {
  pageType: PageType | null;
}

export const state = (): UIState => ({
  pageType: null,
});

export const mutations: MutationTree<UIState> = {
  [SET_PAGE_TYPE] (state, payload: PageType | null) {
    state.pageType = payload;
  },
};

export const getters: GetterTree<UIState, IRootState> = {
  isHealthCategory: (state: UIState): boolean => {
    return state.pageType === 'category-health';
  },
  isHealthArticle: (state: UIState): boolean => {
    return state.pageType === 'health';
  },
  isHealthPage: (state: UIState): boolean => {
    return state.pageType === 'page';
  },
};

export function processingGroup (content: TContent): TContent {
  if (content.children[0].type === 'core__group') {
    return content.children[0];
  }

  return {
    ...content,
    children: content.children.filter(elem => elem.type === 'core__image'),
  };
}

// TODO: разбить на отдельные модули как сделал с seo и adfoxParams
import Vue from 'vue';
import { MutationTree, GetterTree } from 'vuex';

import separateScriptsAndContent from '~/libs/separateScriptsAndContent';
import { getUniqueArrayByProp } from '~/utils/arrays';
import { ADD_ARTICLE_DATA, UPDATE_CURRENT_ARTICLES, ADD_ARCHIVE_DATA } from '~/constants';
import { trimWpDomainInPlace } from '~/utils/trimWpDomain';

export const namespaced = false;

type ArticlePageDataItem = {
  timestamp: number;
  article: ISingleArticle | null;
  visibilityData: VisibilityCondition[],
};
type ArchivePageDataItem = {
  timestamp: number;
  articles: IArticle[];
  title: string;
  totalPages: number;
  visibilityData: VisibilityCondition[];
  icon: string;
}
export type PagesData = {
  [propName: string]: ArticlePageDataItem | ArchivePageDataItem;
};

export const state = (): PagesData => ({});

export const getters: GetterTree<PagesData, IRootState> = {
  currentPageData (state: PagesData, _getters, rootState): ArticlePageDataItem | ArchivePageDataItem {
    // @ts-ignore
    const pagesDataRoute = rootState.route.fullPath.replace(rootState.route.hash, '');
    return state[pagesDataRoute] || null;
  },
  currentArticles (_state: PagesData, getters): IArticle[] | [] {
    return getters.currentPageData?.articles ?? [];
  },
  currentArticle (_state: PagesData, getters): ISingleArticle | null {
    return getters.currentPageData?.article ?? null;
  },
  currentTitle (_state, getters): string {
    return getters.currentPageData?.title ?? '';
  },
  currentIcon (_state, getters): string {
    return getters.currentPageData?.icon ?? '';
  },
  currentTotalPages (_state, getters): number {
    return getters.currentPageData?.totalPages ?? 0;
  },
  isJumperPost (_state, getters): boolean {
    return getters.currentArticle?.meta?.jumperScroller?.isActive ?? false;
  },
  isAdvertisingPost (_state, getters): boolean {
    return getters.currentArticle?.isAdvertising ?? false;
  },
  isPromo (_state, getters): boolean {
    return getters.currentArticle?.isPromo ?? false;
  },
  isTemplateTableOfContents (_state, getters): boolean {
    return getters.currentArticle?.template === 'toc';
  },
  isTemplateBroadside (_state, getters): boolean {
    return getters.currentArticle?.template === 'broadside';
  },
  isArticleTypePost (_state, getters): boolean {
    return (getters.currentArticle?.type === 'post');
  },
  isArticleTypePage (_state, getters): boolean {
    return (getters.currentArticle?.type === 'page');
  },
  currentVisibilityData (_state, getters): VisibilityCondition[] {
    return getters?.currentPageData?.visibilityData ?? [];
  },
};

type ArticlePayload = {
  article: ISingleArticle,
};
type ArchivePayload = {
  articles: IArticle[];
  totalPages: number;
  title: string;
  type?: 'category' | 'spec_projects';
  id?: number;
  icon: string;
  breadcrumb?: Array<{title: string, url: string }>
};

export const getVisibilityDataForArticle = (article: ISingleArticle, routeName: string): VisibilityCondition[] => {
  const visibilityData: VisibilityCondition[] = [];

  if (article.type === 'page') {
    visibilityData.push({
      type: 'post_type' as unknown as VisibilityCondition['type'],
      value: 'page',
    });
  } else if (article.isAdvertising) {
    visibilityData.push({
      type: 'post_type' as unknown as VisibilityCondition['type'],
      value: 'partner',
    });
  }
  article.categories.forEach((category) => {
    const correctCategory = routeName === 'health-article' ? 'category-health' : 'category';
    visibilityData.push({
      type: correctCategory as unknown as VisibilityCondition['type'],
      id: +category.id,
    });
  });
  article.tags.forEach((tag) => {
    visibilityData.push({
      type: 'post_tag' as unknown as VisibilityCondition['type'],
      id: tag.id,
    });
  });
  return visibilityData;
};

export const mutations: MutationTree<PagesData> = {
  [ADD_ARTICLE_DATA] (state, payload: {path: string, data: ArticlePayload, routeName: string}) {
    const { path, data, routeName } = payload;

    const { article } = data;

    const contentAndScripts = separateScriptsAndContent(article.structuredContent);
    const value: ISingleArticle = {
      ...article,
      ...contentAndScripts,
    };
    const visibilityData: VisibilityCondition[] = getVisibilityDataForArticle(article, routeName);

    const timestamp = new Date().getTime();
    trimWpDomainInPlace(value, ['tags.url', 'categories.url', 'meta.breadcrumb.url']);

    Vue.set(state, path, {
      timestamp,
      visibilityData: Object.freeze(visibilityData),
      article: Object.freeze(value),
    });
  },

  [ADD_ARCHIVE_DATA] (state, { path, data }: { path: string, data: ArchivePayload }) {
    const { articles, totalPages, title, type, id, icon, breadcrumb } = data;

    const timestamp = new Date().getTime();
    const visibilityData: VisibilityCondition[] = [];
    if (type && id) {
      visibilityData.push({
        id,
        type: type as unknown as VisibilityCondition['type'],
      });
    }

    const uniqArticles = getUniqueArrayByProp(articles, 'id') as IArticle[];
    trimWpDomainInPlace(uniqArticles, ['author.profile', 'categories.url', 'meta.breadcrumb.url']);

    Vue.set(state, path, {
      timestamp,
      title,
      totalPages,
      visibilityData: Object.freeze(visibilityData),
      articles: uniqArticles.map((item: any) => Object.freeze(item)),
      icon,
      breadcrumb,
    });
  },

  [UPDATE_CURRENT_ARTICLES] (state, { path, newArticles }: { path: string, newArticles: IArticle[] }) {
    const currentArticles = (state[path] as ArchivePageDataItem)?.articles ?? [];
    const newValue = getUniqueArrayByProp([...currentArticles, ...newArticles], 'id') as IArticle[];
    (state[path] as ArchivePageDataItem).articles = newValue.map(item => Object.freeze(item));
  },
};

type Status = 'initial' | 'loading' | 'ready' | 'error';
const statusMap = new Map<string, Status>();

export const createScriptIfNeeded = (url: string) => {
  let script: HTMLScriptElement | null = document.querySelector(`script[src="${url}"]`);
  const status = statusMap.get(url) ?? 'initial';

  if (status === 'error') {
    return Promise.reject(new Error(`Failed to load script: ${url}`));
  }

  if (status === 'ready') {
    return Promise.resolve();
  }

  if (script && status === 'loading') {
    return new Promise((resolve, reject) => {
      script!.addEventListener('error', reject);
      script!.addEventListener('load', resolve);
    });
  }

  if (status === 'initial') {
    return new Promise((resolve, reject) => {
      script = document.createElement('script');
      script.src = url;
      script!.addEventListener('error', reject);
      script!.addEventListener('load', resolve);

      const parent = document.body || document.documentElement;
      parent.append(script);
    });
  }
};

export const removeScript = (url: string): void => {
  const script = document.querySelector(`script[src="${url}"]`);
  script?.remove();
};

import { processingContent } from './processingContent';
import { processingContent as processingContentAmp } from './Amp/processingContent';
import { isValidContent } from './validateContent';

export const prepareContent = (content: TContent[], isAMP: boolean): TContent[] => {
  const preparedContent = content.reduce((result: TContent[], contentItem: TContent) => {
    // валидация/фильтрация контента
    if (!isValidContent(contentItem)) {
      return result;
    }

    const processedContent = processingContent(contentItem);

    // рекурсивная обработка вложенного контента
    processedContent.children = prepareContent(processedContent.children, isAMP);

    try {
      if (isAMP) {
        const processedContentAmp = processingContentAmp(processedContent);

        if (processedContentAmp) {
          result.push(processedContentAmp);
        } else if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line
          console.warn(
            `Not processed amp component with type: ${contentItem.id}, id: ${contentItem.type}`,
          );
        }
      } else {
        // обработка контента
        result.push(processedContent);
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error(
        `Processed content error with type: ${processedContent.type}, id: ${processedContent.id} - `,
        e,
      );
    }

    return result;
  }, []);

  return preparedContent;
};

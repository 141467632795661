export const processingYandexMaps = (content: TContent): TContent => {
  const htmlWrapper = content.children[0];

  if (!htmlWrapper) {
    return content;
  }

  const htmlContentWrapper = htmlWrapper.children[0];

  if (!htmlContentWrapper) {
    return content;
  }

  const iframeElem = htmlContentWrapper.children.find(elem => elem.type === 'iframe');

  if (!iframeElem) {
    return content;
  }

  return { ...iframeElem, attributes: { ...iframeElem.attributes, isCustomBlock: true } };
};

import Vue from 'vue';
import { MutationTree, GetterTree } from 'vuex';

import { ADD_SEO_BY_PATH } from '~/constants';

export const namespaced = false;

export const DEFAULT_SEO = {
  title: 'Лайфхакер',
  description: 'Лайфхакер - советы и лайфхаки, продуктивность, технологии, здоровье',
  robots: '',
  canonical: '',
  schema_graph: '',
  schema_recipe: '',
  og: {
    image: '',
  },
  schema_howto: '',
};

type SeoState = {
  byPath: {
    [path: string]: Seo;
  };
  default: Seo;
};
export const state = (): SeoState => ({
  byPath: {},
  default: DEFAULT_SEO,
});

export const getters: GetterTree<SeoState, IRootState> = {
  currentSeo (state, _getters, rootState): Seo {
    // нет типов для route
    // @ts-ignore
    const currentPath = rootState.route.fullPath.replace(rootState.route.hash, '');
    return state.byPath[String(currentPath)] ?? state.default;
  },
};

export const mutations: MutationTree<SeoState> = {
  [ADD_SEO_BY_PATH] (state, { path, seo }: { path: string, seo: Seo }) {
    Vue.set(state.byPath, path, Object.freeze(seo));
  },
};

export const processingButton = (content: TContent): TContent => {
  return {
    id: content.id,
    type: 'core__buttons',
    attributes: {
      ...content.attributes,
      button: content.type === 'core__button' ? content : content.children[0],
    },
    text: '',
    children: [],
    parser_type: 'gutenberg_parser',
  };
};

export function processingParagraph (content: TContent): TContent {
  if (content.children[0].type === 'p') {
    return content.children[0];
  }

  return {
    ...content,
    parser_type: 'html_parser',
    type: 'p',
  };
}

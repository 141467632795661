
export function formattingIMApitoDefaultApi (data: ISinglePostServerResponse): ISingleArticleServerResponse {
  return {
    item: {
      ...formattingItemSection(data.item),
      meta: {
        ...formattingMetaSection(data.meta),
      },
    },
    seo: formattingSeoSection(data.seo),
    isUserLoggedIn: data.meta.is_user_logged_in,
    adfoxParams: data.meta.adfox_params,
  };
}

export function formatIMArticleList (data: any): any {
  const meta = data.meta ? formattingMetaSection(data.meta) : undefined;

  return {
    items: data.items?.map((item: ISingleArticleServerResponse) => formattingItemSection(item)),
    title: data.item?.title ?? data.item?.name,
    isUserLoggedIn: data?.meta?.is_user_logged_in,
    adfoxParams: data?.meta?.adfox_params,
    ...data.item,
    ...meta,
    seo: formattingSeoSection(data.seo),
  };
}

function renameKeys (obj: any, newKeys: Record<string, string>) {
  const entries = Object.keys(obj).map((key: string) => {
    const newKey = newKeys[key] || key;

    return {
      [newKey]: obj[key],
    };
  });

  return Object.assign({}, ...entries);
}

export function formattingItemSection (item: any): any {
  const keysToRename: Record<string, string> = {
    teaser_url: 'teaserUrl',
    test_pixel: 'testPixel',
    is_advertising: 'isAdvertising',
    is_promo: 'isPromo',
    default_img: 'defaultImg',
    comments_enabled: 'commentsEnabled',
    ads_token: 'adsToken',
    reactions_enabled: 'canHaveReactions',
    advert_label: 'advertLabel',
    parsed_content: 'structuredContent',
    last_modified: 'lastModified',
    amp_enabled: 'ampEnabled',
    json_ld: 'jsonLd',
  };

  return {
    ...renameKeys(item, keysToRename),
    // img deprecated - на момент написания содержит старые размеры
    // для поддержки legacy прокидываем размеры из image.sizes
    img: item.image?.sizes ?? [],
  };
}

export function formattingSeoSection (seo: any) {
  const keysToRename: Record<string, string> = {
    schema: 'schema_graph',
  };

  const formattedSeoSection = {
    ...renameKeys(seo, keysToRename),
    og: {
      image: seo.og_image,
    },
  };

  delete formattedSeoSection.og_image;

  return formattedSeoSection;
}

function formattingMetaSection (meta: any) {
  const keysToRename: Record<string, string> = {
    main_color: 'mainColor',
    jumper_scroller: 'jumperScroller',
    custom_authors: 'customAuthors',
    ads_source: 'adsSource',
    total_items: 'totalItems',
    total_pages: 'totalPages',
    current_page: 'currentPage',
  };

  const formattedMetaSection = {
    ...renameKeys(meta, keysToRename),
  };

  return formattedMetaSection;
}

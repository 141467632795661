import { prepareContent } from '../../prepareContent';
import { QuoteAttributes } from '~/types/components/BQuote';
import { sanitizeAmp } from '~/libs/sanitize-amp';

export function processingQuote (content: TContent<QuoteAttributes>): TContent<QuoteAttributes> {
  return {
    ...content,
    attributes: {
      ...content.attributes,
      details: sanitizeAmp(prepareContent(content.attributes.details, true)),
      text: sanitizeAmp(prepareContent(content.attributes.text, true)),
      title: sanitizeAmp(prepareContent(content.attributes.title, true)),
    },
  };
}

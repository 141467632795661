import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { getSelected } from '~/containers/FilterContainer/pureFunctions';
import {
  MenuItem,
  SelectedMenuItem,
  FetchIngredientsPayload,
  MenuValues,
  FilterAnimate,
} from '~/containers/FilterContainer/types';
import {
  FETCH_FILTERSETTINGS,
  FETCH_INGREDIENTS,
  FETCH_MENUS,
  FETCH_RECIPESCOUNT,
  SET_DISHES,
  SET_FALSEISFIXEDSUBMITBUTTON,
  SET_ISANIMATE,
  SET_RECIPESCOUNT,
  SET_TRUEISFIXEDSUBMITBUTTON,
  SET_OPENEDTAB,
  SET_ANIMATE,
  SET_SORTDIRECTION,
  SET_DISHESTWICE,
  ACTION_HANDLER_TABLET,
  ACTION_HANDLER_PHONE,
  ACTION_HANDLER_DESKTOP,
} from '~/constants/filter';

export const namespaced = true;
export interface FilterState {
  dishes: MenuItem[],
  recipesCount: number;
  isAnimate: boolean;
  isFixedSubmitButton: boolean;
  openedMenu: MenuValues | '';
  animate: FilterAnimate;
  sortDirection: 'asc'| 'desc';
  dishesTwice: boolean;
}

export const state = (): FilterState => ({
  dishes: [],
  recipesCount: 0,
  isAnimate: true,
  isFixedSubmitButton: true,
  openedMenu: '',
  animate: {
    dishes: true,
    cuisines: true,
    includedIngredients: true,
    excludedIngredients: true,
  },
  sortDirection: 'asc',
  dishesTwice: false,
});
export const getters = <GetterTree<FilterState, IRootState>>{
  selectedDishes (state: FilterState) {
    return state.dishes.filter(getSelected) as SelectedMenuItem[];
  },
};

export const mutations = <MutationTree<FilterState>>{
  [SET_DISHES] (state: FilterState, payload: MenuItem[]) {
    state.dishes = payload;
  },
  [SET_RECIPESCOUNT] (state: FilterState, payload: number) {
    state.recipesCount = payload;
  },
  [SET_ISANIMATE] (state: FilterState, payload: boolean) {
    state.isAnimate = payload;
  },
  [SET_TRUEISFIXEDSUBMITBUTTON] (state: FilterState) {
    state.isFixedSubmitButton = true;
  },
  [SET_FALSEISFIXEDSUBMITBUTTON] (state: FilterState) {
    state.isFixedSubmitButton = false;
  },
  [SET_OPENEDTAB] (state: FilterState, payload) {
    state.openedMenu = payload;
  },
  [SET_ANIMATE] (state: FilterState, { type, value }: {type: keyof FilterAnimate, value: boolean}) {
    state.animate[type] = value;
  },
  [SET_OPENEDTAB] (state: FilterState, payload) {
    state.openedMenu = payload;
  },
  [SET_OPENEDTAB] (state: FilterState, payload) {
    state.openedMenu = payload;
  },
  [SET_OPENEDTAB] (state: FilterState, payload) {
    state.openedMenu = payload;
  },
  [SET_SORTDIRECTION] (state: FilterState, payload) {
    state.sortDirection = payload;
  },
  [SET_DISHESTWICE] (state: FilterState, payload) {
    state.dishesTwice = payload;
  },
};

export const actions: ActionTree<FilterState, IRootState> = {
  async [FETCH_FILTERSETTINGS] (_, { path }): Promise<any> {
    try {
      const { data } = await this.$wordpressApi.fetchFilterSettings(path);
      return data;
    } catch (error) {
      /* eslint-disable-next-line */
      console.error(error);
    }
    return null;
  },
  async [FETCH_RECIPESCOUNT] ({ commit }, { url }): Promise<void> {
    try {
      const { data: { count } } = await this.$wordpressApi.fetchRecipesCount(url);
      commit(SET_RECIPESCOUNT, count || 0);
    } catch (error) {
      // @ts-ignore
      if (error.response.status === 404) {
        commit(SET_RECIPESCOUNT, 0);
      } else {
        /* eslint-disable-next-line */
        console.error('fetch recipes count error: ', error);
      }
    }
  },

  async [FETCH_MENUS] (): Promise<any> {
    try {
      const { data } = await this.$wordpressApi.fetchFilterMenus();
      return data;
    } catch (error) {
      /* eslint-disable-next-line */
      console.error(error);
    }
    return null;
  },

  async [FETCH_INGREDIENTS] (_, { searchValue, type }:FetchIngredientsPayload): Promise<any> {
    try {
      let { data: { items } } = await this.$wordpressApi.searchIngredients(searchValue);

      if (!items) {
        items = [];
      }

      return { items, type };
    } catch (error) {
      /* eslint-disable-next-line */
      console.error(error);
    }
  },
  [ACTION_HANDLER_PHONE]: ({ commit }) => {
    commit(SET_ANIMATE, { type: 'dishes', value: true });
    commit(SET_ANIMATE, { type: 'cuisines', value: true });
    commit(SET_ANIMATE, { type: 'includedIngredients', value: true });
    commit(SET_ANIMATE, { type: 'excludedIngredients', value: true });
    commit(SET_DISHESTWICE, true);
    commit(SET_SORTDIRECTION, 'asc');
  },
  [ACTION_HANDLER_TABLET]: ({ commit }) => {
    commit(SET_ANIMATE, { type: 'dishes', value: true });
    commit(SET_ANIMATE, { type: 'cuisines', value: true });
    commit(SET_ANIMATE, { type: 'includedIngredients', value: true });
    commit(SET_ANIMATE, { type: 'excludedIngredients', value: true });
    commit(SET_DISHESTWICE, false);
    commit(SET_SORTDIRECTION, 'asc');
  },
  [ACTION_HANDLER_DESKTOP]: ({ commit }) => {
    commit(SET_ANIMATE, { type: 'dishes', value: true });
    commit(SET_ANIMATE, { type: 'cuisines', value: false });
    commit(SET_ANIMATE, { type: 'includedIngredients', value: false });
    commit(SET_ANIMATE, { type: 'excludedIngredients', value: false });
    commit(SET_DISHESTWICE, false);
    commit(SET_SORTDIRECTION, 'desc');
  },
};

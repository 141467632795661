import Vue from 'vue';
import { ActionTree, MutationTree } from 'vuex';
import type { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import {
  GET_FOLDERS,
  SET_FOLDERS,
  CLEAR_FOLDERS,
  DELETE_FOLDER,
  ADD_FOLDER,
  RENAME_FOLDER,
} from '~/constants';
import { ACCESS_TOKEN_COOKIE_NAME } from '~/constants/config';
import { getSubId } from '~/utils';

export const namespaced = false;

export interface FoldersState {
  items: Array<IFolderItem>,
  total: number,
};

export const state = (): FoldersState => ({
  items: [],
  total: 0,
});

export const actions: ActionTree<FoldersState, IRootState> = {
  [GET_FOLDERS] (
    { commit },
    // { page = 1, limit = 10 },
  ) {
    const subId = getSubId();
    if (subId) {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME) as string;

      return Promise.all([
        this.$authApi.fetchFolders(subId, accessToken),
        this.$authApi.getDefaultFavorites(subId, accessToken),
      ]).then(([respFolders, respDefaultFavorites]: Array<AxiosResponse>) => {
        if (respFolders && respFolders.status === 200 &&
            respDefaultFavorites && respDefaultFavorites.status === 200
        ) {
          const folders = respFolders.data.data
            .map((folderItem: any) => {
              return {
                id: folderItem.id,
                name: folderItem.name,
                total: folderItem.favorites.length,
              };
            })
            .sort((a: IFolderItem, b: IFolderItem) => {
              if (a.id !== null && b.id !== null) {
                return b.id - a.id;
              } else {
                return 0;
              }
            }) as Array<IFolderItem>;

          // кол-во избранного в пользовательских папках
          const favoritesInFolders = respFolders.data.data.reduce((count: number, folder: any) => {
            count += folder.favorites.length;
            return count;
          }, 0);

          // папка Общее
          // todo: вместо null нужен 0
          const defaultFolder = {
            id: null,
            name: 'Общее',
            total: respDefaultFavorites.data.meta.total + favoritesInFolders,
          } as IFolderItem;

          commit(SET_FOLDERS, {
            folders: {
              items: [
                defaultFolder,
                ...folders,
              ],
              total: respFolders.data.meta.total,
            },
          });
        }
      }).catch(() => {});
    }
  },
  [DELETE_FOLDER] (
    /* eslint-disable-next-line */
    {},
    { folder },
  ) {
    const subId = getSubId();
    if (subId) {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME) as string;

      return this.$authApi.deleteFolder(
        folder.id,
        subId,
        accessToken,
      );
    }
  },
  [ADD_FOLDER] (
    /* eslint-disable-next-line */
    {},
    { name },
  ) {
    const subId = getSubId();
    if (subId) {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME) as string;

      return this.$authApi.addFolder(
        name,
        subId,
        accessToken,
      );
    }
  },
  [RENAME_FOLDER] (
    /* eslint-disable-next-line */
    {},
    { name, folderId },
  ) {
    const subId = getSubId();
    if (subId) {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME) as string;

      return this.$authApi.renameFolder(
        { name, folderId },
        subId,
        accessToken,
      );
    }
  },
};

export const mutations: MutationTree<FoldersState> = {
  [SET_FOLDERS] (state, { folders }) {
    Vue.set(state, 'items', folders.items);
    Vue.set(state, 'total', folders.total);
  },

  [CLEAR_FOLDERS] (state) {
    state.items = [];
    state.total = 0;
  },
};

import Vue from 'vue';
import { GetterTree, MutationTree } from 'vuex';

import { SET_RECIPES_VISIBILITY_DATA } from '~/constants';

interface VisibilityDataState {
  byPath: {
    [path: string]: RecipesVisibilityData;
  };
}

const DEFAULT: RecipesVisibilityData = {
  ingredients: [],
  dishTypes: [],
  cuisines: [],
  keywords: [],
  cookingMethods: [],
};

export const namespaced = false;

export const state = (): VisibilityDataState => ({
  byPath: {},
});

export const getters: GetterTree<VisibilityDataState, IRootState> = {
  currentRecipesVisibilityData (state, _getters, rootState): RecipesVisibilityData {
    // @ts-ignore
    return state.byPath[rootState.route.fullPath] ?? DEFAULT;
  },
};

export const mutations: MutationTree<VisibilityDataState> = {
  [SET_RECIPES_VISIBILITY_DATA] (state, payload: { fullPath: string, data: RecipesVisibilityData }) {
    Vue.set(state.byPath, payload.fullPath, Object.freeze(payload.data));
  },
};

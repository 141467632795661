export const processingTelegram = (content: TContent): TContent => {
  const htmlWrapper = content.children[0];

  if (!htmlWrapper) {
    return content;
  }

  const scriptContent = htmlWrapper.children.find(item => item.type === 'script');

  if (!scriptContent) {
    return content;
  }

  return {
    ...scriptContent,
    attributes: {
      ...scriptContent.attributes,
      isCustomBlock: true,
    },
  };
};

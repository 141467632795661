// Лежит в cdn, выводит страницу переданную в src в iframe и отправляет сообщение о изменении высоты в amp
const CUSTOM_TG_PAGE = 'https://cdn.lifehacker.ru/wp-content/uploads/telegram.html?src=';

const tgToIFrame = (content: TContent): TContent => {
  const { attributes } = content;

  const mainSrc = 'https://t.me/';
  const buildedIframeSrc = new URL(mainSrc + attributes['data-telegram-post']);

  buildedIframeSrc.searchParams.append('embed', '1');
  buildedIframeSrc.searchParams.append('userpic', attributes['data-userpic'] || true);
  buildedIframeSrc.searchParams.append('color', attributes['data-color']);
  buildedIframeSrc.searchParams.append('dark_color', attributes['data-dark-color']);
  buildedIframeSrc.searchParams.append('dark', attributes['data-dark']);

  return {
    id: content.id,
    type: 'iframe',
    parser_type: 'html_parser',
    children: [],
    text: '',
    attributes: {
      ...content.attributes,
      src: CUSTOM_TG_PAGE + buildedIframeSrc.href,
      // default height for loader
      height: '300',
      width: 'auto',
      layout: 'fixed-height',
    },
  };
};

export const processingScript = (content: TContent): TContent | undefined => {
  const scriptObject = content.children[0];

  if (content.attributes.src?.includes('telegram')) {
    return tgToIFrame(content);
  }

  if (scriptObject?.text?.includes('VK.Widgets.Post')) {
    const scriptText = scriptObject.text;
    const postArgs = scriptText.match(/Post\((.*?)\)/)?.[1].split(',');

    if (!postArgs) {
      return;
    }

    return {
      ...scriptObject,
      attributes: {
        providerNameSlug: 'vk',
        vid: postArgs[2],
        ownerId: postArgs[1],
        hash: postArgs[3].replace(/'/g, ''),
      },
      type: 'core__embed',
      parser_type: 'gutenberg_parser',
    };
  }

  return content;
};

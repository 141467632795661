// модуль для UI элементов: шапки, меню и тд
// TODO: перенести сюда все что к этому относится

import { MutationTree } from 'vuex';

import {
  HIDE_HEADER,
  SHOW_HEADER,

  HIDE_PRIMARY_MENU,
  SHOW_PRIMARY_MENU,

  HIDE_MODAL_DELETE_ACCOUNT,
  SHOW_MODAL_DELETE_ACCOUNT,
} from '~/constants';

export const namespaced = false;

export interface UIState {
  isHeaderShow: boolean;
  isPrimaryMenuShow: boolean;
  isModalDeleteAccountShow: boolean;
}

export const state = (): UIState => ({
  isHeaderShow: true,
  isPrimaryMenuShow: true,
  isModalDeleteAccountShow: false,
});

export const mutations: MutationTree<UIState> = {
  [HIDE_HEADER] (state) {
    state.isHeaderShow = false;
  },
  [SHOW_HEADER] (state) {
    state.isHeaderShow = true;
  },

  [HIDE_PRIMARY_MENU] (state) {
    state.isPrimaryMenuShow = false;
  },
  [SHOW_PRIMARY_MENU] (state) {
    state.isPrimaryMenuShow = true;
  },

  [HIDE_MODAL_DELETE_ACCOUNT] (state) {
    state.isModalDeleteAccountShow = false;
  },
  [SHOW_MODAL_DELETE_ACCOUNT] (state) {
    state.isModalDeleteAccountShow = true;
  },
};

import { MutationTree, GetterTree } from 'vuex';
import { SET_ARTICLE_COMMENTS_SERVICE_ERROR, SET_RECIPE_COMMENTS_SERVICE_ERROR } from '~/constants';

export const namespaced = false;

export interface ServiceErrorsState {
  articleCommentsError: boolean;
  recipeCommentsError: boolean;
};

export const state = (): ServiceErrorsState => ({
  articleCommentsError: false,
  recipeCommentsError: false,
});

export const getters: GetterTree<ServiceErrorsState, IRootState> = {
  articleCommentsServiceError (state): boolean {
    return state.articleCommentsError;
  },

  recipeCommentsServiceError (state): boolean {
    return state.recipeCommentsError;
  },
};

export const mutations: MutationTree<ServiceErrorsState> = {
  [SET_ARTICLE_COMMENTS_SERVICE_ERROR] (state, { isError }) {
    state.articleCommentsError = isError;
  },

  [SET_RECIPE_COMMENTS_SERVICE_ERROR] (state, { isError }) {
    state.recipeCommentsError = isError;
  },
};

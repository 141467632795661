import { sanitizeAmp } from '~/libs/sanitize-amp';
import { PromoBlockAttributes } from '~/types/components/PromoBlock';

export const processingPromoBlock = (
  content: TContent<PromoBlockAttributes>,
): TContent<PromoBlockAttributes> => {
  return {
    ...content,
    attributes: {
      ...content.attributes,
      logo: content.attributes.logo
        ? sanitizeAmp(content.attributes.logo)
        : content.attributes.logo,
    },
  };
};

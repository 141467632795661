import { MutationTree, ActionTree } from 'vuex';
import Vue from 'vue';

import {
  FETCH_QUICK_POLL,
  FETCH_QUICK_POLL_STAT,
  SET_QUICK_POLL,
  SET_CURRENT_QUICK_POLL,
  SET_QUICK_POLL_STAT,
  SET_QUICK_POLL_STATE,
  UPDATE_QUICK_POLL_ANSWER,
  SET_QUICK_POLL_SELECTED_ANSWER,
  SET_QUICK_POLL_LOCATION,
  CLEAR_QUICK_PULL_LOCATION,
} from '~/constants';

// типы
type QuickPoll = {
  id: number;
  name: string;
  theme?: number;
  question?: string;
  answers?: Array<QuickPollAnswer>;
  article?: Article;
  testPixel?: string;
  statistics?: QuickPollStat | null;
  state?: 'initial' | 'viewed' | 'answered';
  selectedAnswer?: number | null;
  location?: string;
  isCurrent?: boolean;
}
type Cover = {
  id: number;
  width: number;
  height: number
  url: string;
  mobile_url: string;
  thumb_url: string;
}
type QuickPollAnswer = {
  id: number,
  text: string
}
type Article = {
  id: number;
  url: string;
  cover: Cover | null;
  title: string;
}
type QuickPollStat = {
  [answerId: string]: number;
}

export interface IQuickPollsState {
  polls: {
    byId: Record<string, QuickPoll>;
  },
  pollsByLocation: {
    byId: Record<string, string>;
  }
}

export const namespaced = false;

export const state = (): IQuickPollsState => ({
  polls: {
    byId: {},
  },
  pollsByLocation: {
    byId: {},
  },
});

export const actions: ActionTree<IQuickPollsState, IRootState> = {
  [FETCH_QUICK_POLL] ({ commit, dispatch }, poll) {
    return this.$quizApi.getQuickPoll(poll.id)
      .then((response) => {
        const { data: { data: { data: responsePoll } } } = response;

        commit(SET_QUICK_POLL, {
          ...responsePoll,
          state: poll.viewed ? 'viewed' : 'initial',
          selectedAnswer: poll.answer ?? null,
          viewed: poll.viewed ?? false,
          location: poll.location,
          // widgetId: poll.widgetId,
        });

        if (poll.answer) {
          dispatch(
            FETCH_QUICK_POLL_STAT,
            poll.id,
          );
        }
      });
  },
  [FETCH_QUICK_POLL_STAT] ({ commit }, quickPollId) {
    return this.$quizApi.getQuickPollStatistics(quickPollId)
      .then((response) => {
        const { data: { data: { clicks: statistics } } } = response;

        commit(SET_QUICK_POLL_STAT, { quickPollId, statistics });
        commit(
          SET_QUICK_POLL_STATE, {
            quickPollId,
            statePoll: 'answered',
          },
        );
      });
  },
  // tslint:disable-next-line
  [UPDATE_QUICK_POLL_ANSWER] ({ commit }, payload) {
    const { quickPollId, answerId } = payload;
    return this.$quizApi.setQuickPollAnswerId(answerId)
      .then(() => {
        commit(SET_QUICK_POLL_SELECTED_ANSWER, { quickPollId, answerId });
      });
  },
};

export const mutations: MutationTree<IQuickPollsState> = {
  [SET_QUICK_POLL_LOCATION] (state, payload) {
    const { quickPollId, widgetId } = payload;

    Vue.set(
      state.pollsByLocation.byId,
      `${widgetId}`,
      quickPollId,
    );
  },

  [SET_QUICK_POLL] (state, payload) {
    Vue.set(state.polls.byId, payload.id, payload);
  },
  [SET_CURRENT_QUICK_POLL] (state, { poll }) {
    Object.keys(state.polls.byId).forEach((id) => {
      const pollState = state.polls.byId[id];
      if (pollState.location === poll.location) {
        Vue.set(state.polls.byId[poll.id], 'isCurrent', pollState.id === poll.id);
      }
    });
  },
  [SET_QUICK_POLL_STAT] (state, payload) {
    const { quickPollId, statistics } = payload;
    Vue.set(state.polls.byId[quickPollId], 'statistics', statistics);
  },
  [SET_QUICK_POLL_STATE] (state, payload) {
    const { quickPollId, statePoll } = payload;

    Vue.set(state.polls.byId[quickPollId], 'state', statePoll);
  },
  [SET_QUICK_POLL_SELECTED_ANSWER] (state, payload) {
    const { quickPollId, answerId } = payload;

    Vue.set(state.polls.byId[quickPollId], 'selectedAnswer', answerId);
  },
  [CLEAR_QUICK_PULL_LOCATION] (state) {
    Vue.set(state.pollsByLocation, 'byId', {});
  },
};

import Vue from 'vue';
import { MutationTree, GetterTree } from 'vuex';

import {
  ADD_SIMILAR_RECIPES,
  ADD_RELATED_RECIPES,
  UPDATE_CURRENT_RECIPE_ID,
  GET_CURRENT_RELATED_RECIPES,
  GET_CURRENT_SIMILAR_RECIPES,
  GET_CURRENT_SIMILAR_LINK,
  GET_CURRENT_RELATED_LINK,
} from '~/constants';
import { has } from '~/utils';

export const namespaced = false;

export interface RecipesListWidgetState {
  // похожие рецепты
  similar: {
    // ключ - ид рецепта к которому относятся
    // значение - объект
    //  где idList - список ид похожих рецептов
    //  link - ссылка на страницу с похожими рецептами
    byId: {
      [id: string]: {
        idList: number[];
        link: string;
      };
    };
  },

  // cм. комментарии про similar
  related: {
    byId: {
      [id: string]: {
        idList: number[];
        link: string;
      };
    };
  },

  // хранилище similar и related рецептов
  byId: {
    [id: string]: WidgetRecipe,
  },

  // ид активного рецепта
  currentRecipeId: number | null;
}

export const state = (): RecipesListWidgetState => ({
  similar: {
    byId: {},
  },
  related: {
    byId: {},
  },
  byId: {},
  currentRecipeId: null,
});

export const getters: GetterTree<RecipesListWidgetState, IRootState> = {
  [GET_CURRENT_RELATED_RECIPES] (state): WidgetRecipe[] {
    if (state.currentRecipeId && has(state.related.byId, state.currentRecipeId.toString())) {
      return state.related.byId[state.currentRecipeId.toString()]
        .idList
        .map(item => state.byId?.[item.toString()])
        .filter(item => !!item);
    }
    return [];
  },

  [GET_CURRENT_SIMILAR_RECIPES] (state): WidgetRecipe[] {
    if (state.currentRecipeId && has(state.similar.byId, state.currentRecipeId.toString())) {
      return state.similar.byId[state.currentRecipeId.toString()]
        .idList
        .map(item => state.byId?.[item.toString()])
        .filter(item => !!item);
    }
    return [];
  },

  [GET_CURRENT_SIMILAR_LINK] (state): string {
    if (state.currentRecipeId && has(state.similar.byId, state.currentRecipeId.toString())) {
      return state.similar.byId[state.currentRecipeId.toString()].link;
    }
    return '';
  },

  [GET_CURRENT_RELATED_LINK] (state): string {
    if (state.currentRecipeId && has(state.related.byId, state.currentRecipeId.toString())) {
      return state.related.byId[state.currentRecipeId.toString()].link;
    }
    return '';
  },
};

type Payload = {
  list: WidgetRecipe[];
  id: number;
  link?: string;
}

export const mutations: MutationTree<RecipesListWidgetState> = {
  [ADD_SIMILAR_RECIPES] (state, payload: Payload) {
    payload.list.forEach((item) => {
      Vue.set(state.byId, item.postId, Object.freeze(item));
    });
    Vue.set(state.similar.byId, payload.id, {
      idList: payload.list.map(item => item.postId),
      link: payload.link ?? '',
    });
  },

  [ADD_RELATED_RECIPES] (state, payload: Payload) {
    payload.list.forEach((item) => {
      Vue.set(state.byId, item.postId, Object.freeze(item));
    });
    Vue.set(state.related.byId, payload.id, {
      idList: payload.list.map(item => item.postId),
      link: payload.link ?? '',
    });
  },

  [UPDATE_CURRENT_RECIPE_ID] (state, payload: number | null) {
    state.currentRecipeId = payload;
  },
};

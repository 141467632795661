export const processingLink = (content: TContent): TContent => {
  const { attributes, children } = content;

  // tmp hack should get class from backend
  if (children.some(item => item.type === 'img')) {
    attributes.class = (attributes.class ?? '') + ' image__link';
  }

  return content;
};

import { formattingItemSection } from '~/utils/formattingData';

export function processingUsefulInfo (content: TContent): TContent {
  const formattedArticles = content.attributes.posts.map((article:IArticle) => formattingItemSection(article));

  return {
    ...content,
    attributes: { ...content.attributes, posts: formattedArticles },
  };
}

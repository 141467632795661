import { MutationTree, ActionTree, GetterTree } from 'vuex';
import Vue from 'vue';

// @ts-ignore
import random from 'lodash.random';
import { parseData } from '@devhacker/shared/utils/parseData';
import { PRE_UPDATE_REACTION, SET_IS_PENDING_REACTION } from '../constants/index';
import {
  FETCH_REACTIONS,
  RE_FETCH_REACTIONS,
  UPDATE_REACTIONS,
  UPDATE_LIKE,
  UPDATE_REACTION,
  UPDATE_DISLIKE,
  GET_LIKES,
  GET_DISLIKES,
  GET_REACTIONS_STATE,
  GET_REACTIONS,
  CREATE_INTERSECTION_OBSERVER,
  SET_ISSHOWHEARTBEAT,
  SET_ISBEFORELICKED,
  ADD_INTERSECTION_OBSERVER,
  GET_OBSERVERS,
  DISCONNECT_INTERSECTION_OBSERVER,
} from '~/constants';
import { fetchReactionsSchema, updateReactionsSchema, updateLikeSchema, updateDislikeSchema } from '@/schemas/reactions/fetchReactionsSchema';

import { getSubId } from '~/utils';

export const namespaced = false;

export type ReactionObserverItem = {
  id: string,
  intersectionObserver: IntersectionObserver | null,
  isShowHeartbeat: boolean,
  isBeforeLicked: boolean,
};

export interface ReactionsState {
  byId: Record<number, ReactionFromServer>;
  observers: Array<ReactionObserverItem>;
  isPending: boolean;
}

export const state = (): ReactionsState => ({
  byId: {},
  observers: [],
  isPending: false,
});

export const getters: GetterTree<ReactionsState, IRootState> = {
  [GET_OBSERVERS]: (state: ReactionsState) => (id: string): ReactionObserverItem | null => {
    return state.observers.find((el: ReactionObserverItem) => el.id === id) ?? null;
  },
  [GET_LIKES]: (state: ReactionsState) => (id: number): number => {
    return state.byId[id]?.like_count ?? 0;
  },
  [GET_DISLIKES]: (state: ReactionsState) => (id: number): number => {
    return state.byId[id]?.dislike_count ?? 0;
  },
  [GET_REACTIONS_STATE]: (state: ReactionsState) => (id: number): TReactionType => {
    return state.byId[id]?.user_state;
  },
  [GET_REACTIONS]: (state: ReactionsState) => (id: number) => {
    // icon - для стилизации иконок
    // type - тип реакции для API (на ВЕ уже есть like и dislike,
    // но для ЛХ добавили похожие - thumbs_up и thumbs_down)
    // если на ВЕ будут такие же иконки как на ЛХ, но надо будет
    // оставить старые данные, то необходимо будет в icon указать
    // название новой иконки (thumbs_up/thumbs_down), а в type
    // старый тип для ВЕ (like/dislike)
    // title - пока только для аналитики
    const reactionTypes: IReactionScheme[] = [
      {
        icon: 'heart',
        type: 'heart',
        title: 'сердечко',
      },
      {
        icon: 'thumbs_up',
        type: 'thumbs_up',
        title: 'палец вверх',
      },
      {
        icon: 'on_fire',
        type: 'on_fire',
        title: 'огонь',
      },
      {
        icon: 'thumbs_down',
        type: 'thumbs_down',
        title: 'палец вниз',
      },
      {
        icon: 'angry',
        type: 'angry',
        title: 'сердитый смайлик',
      },
    ];
    return reactionTypes.map((reactionScheme: IReactionScheme): IReaction => {
      // @ts-ignore
      const count: number = (state.byId[id] && state.byId[id][`${reactionScheme.type}_count`]) || 0;

      return { ...reactionScheme, count };
    });
  },
};

export const checkAccessTokenAndDoAction = async (_vm: any, action: any): Promise<void> => {
  if (!getSubId()) {
    const XUserId = JSON.parse(localStorage.getItem('XUserId') || '0');

    if (!XUserId) {
      const generateXUserId = random(-2000000000, -1000000000);
      localStorage.setItem('XUserId', JSON.stringify(generateXUserId));
    }
  }
  return await action();
};

export const actions: ActionTree<ReactionsState, IRootState> = {
  async [FETCH_REACTIONS] ({ commit }, payload: number[]) {
    try {
      const response = await this.$reactionsApi.fetchReactions(payload);
      const parsedResponse = parseData(response.data, fetchReactionsSchema);
      if (parsedResponse.success) {
        const reactions = parsedResponse.data;
        // console.warn('reactions fetch', reactions);

        commit(UPDATE_REACTIONS, reactions);
      } else {
        this.$sentry.captureException(parsedResponse);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.$sentry.captureException(error);
    }
  },

  [PRE_UPDATE_REACTION] ({ state, commit }, payload: {
    id: number,
    user_state: TReactionType,
    isHealth: boolean,
  }) {
    const prevReaction = { ...state.byId[payload.id] };
    const isSameType = prevReaction.user_state === payload.user_state;
    const isSwapType = prevReaction.user_state && !isSameType;

    // @ts-ignore
    const prevCount = prevReaction?.[`${prevReaction.user_state}_count`];
    // @ts-ignore
    const newCount = prevReaction?.[`${payload.user_state}_count`];

    // Предварительно заменяем лайки не дожидаясь ответа от сервера
    commit(UPDATE_REACTIONS, [{
      ...prevReaction,
      user_state: isSameType ? null : payload.user_state,
      ...(
        isSwapType
          ? {
            [`${payload.user_state}_count`]: newCount + 1,
            [`${prevReaction.user_state}_count`]: prevCount - 1,
          }
          : isSameType
            ? { [`${payload.user_state}_count`]: newCount - 1 }
            : { [`${payload.user_state}_count`]: newCount + 1 }
      ),
    }]);
  },
  async [UPDATE_REACTION] ({ state, commit, dispatch }, payload: {
    id: number,
    user_state: TReactionType,
    isHealth: boolean,
  }) {
    // Из-за того, что у нас API работает как toggle
    // мы не можем его запросы просто отменять при повторных кликах
    // поэтому если уже идет запрос, то не отправляем новый
    if (state.isPending) { return; }
    commit(SET_IS_PENDING_REACTION, true);
    dispatch(PRE_UPDATE_REACTION, payload);

    try {
      const response = await this.$reactionsApi.updateReaction(payload.id, payload.user_state, payload.isHealth);
      const parsedResponse = parseData(response.data, updateReactionsSchema);

      if (parsedResponse.success) {
        const reaction = parsedResponse.data;
        commit(UPDATE_REACTIONS, [reaction]);
      } else {
        // eslint-disable-next-line no-console
        console.error(parsedResponse);
        throw new Error('Something went wrong');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.$sentry.captureException(error);
    }

    commit(SET_IS_PENDING_REACTION, false);
  },

  async [UPDATE_LIKE] ({ state, commit, dispatch }, payload: {
    id: number;
  }) {
    // Из-за того, что у нас API работает как toggle
    // мы не можем его запросы просто отменять при повторных кликах
    // поэтому если уже идет запрос, то не отправляем новый
    if (state.isPending) { return; }
    commit(SET_IS_PENDING_REACTION, true);
    dispatch(PRE_UPDATE_REACTION, { id: payload.id, user_state: 'like' });

    try {
      const response = await this.$reactionsApi.updateLike(payload.id);
      const parsedResponse = parseData(response.data, updateLikeSchema);

      if (parsedResponse.success) {
        const reaction = parsedResponse.data;
        commit(UPDATE_REACTIONS, [reaction]);
      } else {
        throw new Error('Something went wrong');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.$sentry.captureException(error);
    }

    commit(SET_IS_PENDING_REACTION, false);
  },
  async [UPDATE_DISLIKE] ({ state, commit, dispatch }, payload: {
    id: number;
  }) {
    // Из-за того, что у нас API работает как toggle
    // мы не можем его запросы просто отменять при повторных кликах
    // поэтому если уже идет запрос, то не отправляем новый
    if (state.isPending) { return; }
    commit(SET_IS_PENDING_REACTION, true);
    dispatch(PRE_UPDATE_REACTION, { id: payload.id, user_state: 'dislike' });

    try {
      const response = await this.$reactionsApi.updateDislike(payload.id);
      const parsedResponse = parseData(response.data, updateDislikeSchema);

      if (parsedResponse.success) {
        const reaction = parsedResponse.data;
        commit(UPDATE_REACTIONS, [reaction]);
      } else {
        this.$sentry.captureException(parsedResponse);
        throw new Error('Something went wrong');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.$sentry.captureException(error);
    }

    commit(SET_IS_PENDING_REACTION, false);
  },
  // Перезагрузка реакций. Например, при логине, разлогине
  async [RE_FETCH_REACTIONS] ({ dispatch, state }) {
    const ids: number[] = Object.keys(state.byId).map(item => +item);
    if (ids.length) {
      await dispatch(FETCH_REACTIONS, ids);
    }
  },

  [CREATE_INTERSECTION_OBSERVER] ({ commit }, payload: ReactionObserverItem) {
    commit(ADD_INTERSECTION_OBSERVER, payload);
  },
  [DISCONNECT_INTERSECTION_OBSERVER] ({ state }) {
    state.observers.forEach((item) => {
      item.intersectionObserver?.disconnect();
    });
  },
};

export const mutations: MutationTree<ReactionsState> = {
  [SET_IS_PENDING_REACTION] (state, payload: boolean) {
    state.isPending = payload;
  },

  [UPDATE_REACTIONS] (state, payload: ReactionFromServer[]) {
    payload.forEach((reaction: ReactionFromServer) => {
      Vue.set(state.byId, reaction.post_id, reaction);
    });
  },

  [ADD_INTERSECTION_OBSERVER] (state, payload: ReactionObserverItem) {
    state.observers.push(payload);
  },
  [SET_ISSHOWHEARTBEAT] (state, payload: { id: string, value: boolean }) {
    const item = state.observers.find(el => el.id === payload.id);
    if (item) {
      item.isShowHeartbeat = payload.value;
    }
  },
  [SET_ISBEFORELICKED] (state, payload: { id: string, value: boolean }) {
    const item = state.observers.find(el => el.id === payload.id);
    if (item) {
      item.isBeforeLicked = payload.value;
    }
  },
};

// Не разрешаем эмбед яндекс карт из core__html.
// isCustomBlock атрибут проставляется в processingContent/lh__yandex-maps.ts
const isYandexMapIframe = ({ type, attributes }: TContent): boolean =>
  type === 'iframe' && attributes.src.includes('yandex.ru/map-widget') && !attributes.isCustomBlock;

export const isValidHtml = (content: TContent): boolean => {
  const { children } = content;

  const contentWrapper = children?.[0];

  if (!contentWrapper) {
    return true;
  }

  if (contentWrapper.children.length && contentWrapper.children.find(isYandexMapIframe)) {
    return false;
  }

  return true;
};

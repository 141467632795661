import { makeAmpViqeoPlayerContent, isViqeoCheck } from '@devhacker/shared/utils/embed';
import { DEFAULT_VIQEO_PLAYER_ID, DEFAULT_VIQEO_PROFILE_ID } from '~/constants';

const UNSUPPORTED_VK_TAGS = ['vk_playlist', 'vk_podcast', 'vk_poll'];

const returnFallbackForUnsupportedTags = (content: TContent): TContent | undefined => {
  const isUnsupported = content.children.some((child) => {
    return (
      child.attributes.id && UNSUPPORTED_VK_TAGS.some(tag => child.attributes.id.includes(tag))
    );
  });

  if (isUnsupported) {
    return {
      ...content,
      type: 'html-fallback-amp',
      parser_type: 'gutenberg_parser',
    };
  }
};

const checkViqeo = (content: TContent): TContent | undefined => {
  const viqeoChild = content.children.find(isViqeoCheck);

  if (viqeoChild) {
    const viqeoPlayerElem = makeAmpViqeoPlayerContent<TContent>(
      viqeoChild,
      DEFAULT_VIQEO_PLAYER_ID,
      DEFAULT_VIQEO_PROFILE_ID,
    );

    return viqeoPlayerElem;
  }
};

export const processingHtml = (content: TContent): TContent => {
  const fallback = returnFallbackForUnsupportedTags(content);
  if (fallback) {
    return fallback;
  }

  const viqeo = checkViqeo(content);
  if (viqeo) {
    return viqeo;
  }

  return content;
};

import { makeViqeoPlayerContent } from '@devhacker/shared/utils/embed';
import { DEFAULT_VIQEO_PLAYER_ID, DEFAULT_VIQEO_PROFILE_ID } from '~/constants';

export function processingEmbed (content: TContent): TContent {
  if (content.attributes.providerNameSlug !== 'youtube') {
    return content;
  }

  const viqeoPlayerElem = {
    ...makeViqeoPlayerContent(content, DEFAULT_VIQEO_PLAYER_ID, DEFAULT_VIQEO_PROFILE_ID),
    type: 'core__html',
  };

  return viqeoPlayerElem;
}

const findImageChild = (elem: TContent): TContent | undefined => {
  const stack = [elem];

  while (stack.length) {
    const child = stack.pop();

    if (child?.type === 'img') {
      return child;
    }

    child?.children && stack.push(...child.children);
  }

  return undefined;
};

export function processingImage (elem: TContent): TContent | undefined {
  const figureWrapper = elem.children[0];

  if (!figureWrapper) {
    return elem;
  }

  const { isBorder, isMobile, description = [] } = elem.attributes;

  // Выбранная рамка, приходит с кривым названием. На самом деле хранит в себе regular или mobile
  const borderType: 'regular' | 'mobile' = isBorder;
  // Элемент обертки картинки, может быть самой картинкой или ссылкой на изображении.
  let imgWrapper = figureWrapper.children[0];

  // Если есть мобильная рамка или атрибут для мобильной рамки то добавляем класс на обертку.
  if (isMobile || borderType === 'mobile') {
  // Класс для обертки мобильного скриншота и мобильной рамки
    const mobileClass = 'mobile-border-wrapper';
    // Если у изображения нет обертки в виде ссылки то создаем ее для добавления мобильной рамки
    if (imgWrapper.type === 'img') {
      imgWrapper = {
        id: Date.now(),
        type: 'div',
        text: '',
        parser_type: 'html_parser',
        attributes: { class: mobileClass },
        children: [figureWrapper.children[0]],
      };

      figureWrapper.children[0] = imgWrapper;
    } else {
      // Если обертка уже есть то не создаеам доп элемент и вешаем класс на сущесвующую обертку
      // Не получается добавить на figure потому что он включает в себя отступы и ссылку на картинку
      imgWrapper.attributes.class += ` ${mobileClass}`;
    }
  }

  // Если есть какая-то рамка то добавляем ее на картинку
  if (borderType) {
    const borderClass = ` ${borderType}-border`;

    if (imgWrapper.type === 'img') {
      // Если у картинки нет обертки то тип будет img и мы можем добавить рамку сразу;
      imgWrapper.attributes.class += borderClass;
    } else {
      // Если любой другой тип (например обернуто в ссылку), то находим элемент самой картинки в
      // глубине обертки и добавляем класс на него если нашли
      const imageChild = findImageChild(figureWrapper);

      if (imageChild) {
        imageChild.attributes.class += borderClass;
      }
    }
  }

  if (description.length) {
    description[0].attributes.class = 'image-description';

    // Возвращаем обертку если есть описание
    return {
      ...elem,
      type: 'div',
      parser_type: 'html_parser',
      attributes: { class: 'image-with-description' },
      children: [figureWrapper, ...description],
    };
  }

  return figureWrapper;
}

import UrlParser from 'url-parse';

export default function getSlugFromUrl (url: string): string {
  const { pathname } = new UrlParser(url);
  const closedSlash = /[^/]$/g;

  return pathname.match(closedSlash)
    ? pathname + '/'
    : pathname;
}

const MIN_ALLOWED_COLUMN = 2;

export function isValidTable (content: TContent) {
  // Таблицы гутенберга обернуты в множество родительских блоков.
  // deepNestedTable доходит до первого ряда tbody
  const deepNestedTableRow = content?.children?.[0]?.children?.[0]?.children?.[0].children.find(
    elem => elem.type === 'tbody'
  )?.children[0];

  const tableRowSize = deepNestedTableRow?.children?.length ?? 0;

  if (tableRowSize >= MIN_ALLOWED_COLUMN) {
    return content;
  }
}

// возвращает массив из массивов длиной chunkSize
export function getArraySplitIntoChunks (array: Array<unknown>, chunkSize = 10): Array<Array<unknown>> {
  let i;
  let j;
  let tempArray;
  const container = [];
  for (i = 0, j = array.length; i < j; i += chunkSize) {
    tempArray = array.slice(i, i + chunkSize);
    container.push(tempArray);
  }
  return container;
}

//
export function getMixedItemsArray (
  firstArray: Array<unknown>,
  secondArray: Array<unknown>,
  firstArrayChunkSize = 1,
  secondArrayChunkSize = 1,
  shouldWeDropLastItem = false,
): Array<{ first: Array<unknown>, second: Array<unknown> }> {
  const splicedFirstArray = getArraySplitIntoChunks(firstArray, firstArrayChunkSize);
  const splicedSecondArray = getArraySplitIntoChunks(secondArray, secondArrayChunkSize);
  return splicedFirstArray.map((currentItem, idx) => {
    let second: Array<unknown> = [];
    if (splicedSecondArray[idx] && !shouldWeDropLastItem) {
      second = splicedSecondArray[idx];
    }
    return {
      first: currentItem,
      second,
    };
  });
}

type StringAnyObject = Record<string, any>;

export function getUniqueArrayByProp (myArr: StringAnyObject[], prop: string) {
  return myArr
    .filter((obj: StringAnyObject, pos: number, arr: StringAnyObject[]) => arr.map((mapObj: StringAnyObject) => mapObj[prop])
      .indexOf(obj[prop]) === pos);
}

// https://learn.javascript.ru/task/shuffle
export function shuffleArray (array: Array<any>): Array<any> {
  const newArr = [...array];
  for (let i = newArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)); // случайный индекс от 0 до i
    [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
  }
  return newArr;
}

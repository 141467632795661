import { sanitizeAmp } from '~/libs/sanitize-amp';

export function processingEmailBlock (content: TContent): TContent {
  return {
    ...content,
    attributes: {
      ...content.attributes,
      title: sanitizeAmp(content.attributes.title),
      subtitle: sanitizeAmp(content.attributes.subtitle),
      descriptor: sanitizeAmp(content.attributes.descriptor),
    },
  };
}

import { AxiosResponse } from 'axios';
import { formattingIMApitoDefaultApi, formatIMArticleList } from '../formattingData';

export const formatToOldApiInterceptor = (response: AxiosResponse<any>) => {
  const {
    data,
    config: { url },
  } = response;

  if (url?.includes('/single-post') || url?.includes('/preview')) {
    return { ...response, data: formattingIMApitoDefaultApi(data) };
  }

  return { ...response, data: formatIMArticleList(data) };
};
